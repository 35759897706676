import React, { useState, useEffect } from "react";
import "./styles.scss";
import uk_logo from "../../assets/Footer2/2024.png";
import twitter from "../../assets/Footer2/twitter.png";
import facebook from "../../assets/Footer2/facebook.png";
import insta from "../../assets/Footer2/insta.png";
import linkdin from "../../assets/Footer2/linkdin.png";
import bird from "../../assets/Footer2/bird.png";
import playstore1 from "../../assets/Footer2/app-store.webp";
import playstore2 from "../../assets/Footer2/google-play.webp";
import man from "../../assets/Footer2/man.png";
import chatbot from "../../assets/Footer2/chatbot.png";
import { footer1, sportsOptions, footer3, footer4 } from "./helper";
import { IoPlaySharp } from "react-icons/io5";
import { TbPlayerStopFilled } from "react-icons/tb";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import uk_banner from "../../assets/home2/Body/uk_12.png";
import uk_changed from "../../assets/home2/Body/uk_changed.png";
import { contactUs } from "assets/png/aboutus/contactUs.png";
import Footerbottom from "components/footer/Footerbottom";

function Footer() {
  const [isChatbotFixed, setIsChatbotFixed] = useState(false);
  const [isActiveFooter, setisActiveFooter] = useState({});

  function openCloseAccordian(name) {
    setisActiveFooter({
      ...isActiveFooter,
      [name]: !Boolean(isActiveFooter[name]),
    });
  }

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Function to update the state with the new window dimensions
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const documentHeight = document.body.scrollHeight;
    const isBottom = windowHeight + scrollY + 100 >= documentHeight;
    // console.log(` {
    //   windowHeight:${windowHeight},
    //   scrollY:${scrollY},
    //   documentHeight:${documentHeight},
    //   isBottom:${isBottom},

    // }`);
    setIsChatbotFixed(!isBottom);
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <div>
      <div className="uttrakhand_footer_page4">
        <div className="f-0_page4">
          <div>
            {/* <img src={uk_logo} alt="uk_logo" class="logo" /> */}
            <div className="thirty-eight-text-footer">
              {/* ३८ */}
              38 <sup className="h-over-sub"> वां </sup>
              {/* <span className="uk_changed_img_footer">
                <img src={uk_changed} />
              </span>
              <span className="uk_lower_img_footer">
                <img src={uk_banner} />
              </span> */}
            </div>
          </div>
          <div>
            <h4 className="fontstyle rasteriya_hindi_font">
              राष्ट्रीय खेल, उत्तराखण्ड
            </h4>
          </div>
          <div className="social_media_logos_page4">
            <div>
              <img src={twitter} alt="uk_logo" class="logo" />
            </div>
            <div>
              <img src={facebook} alt="uk_logo" class="logo" />
            </div>
            <div>
              <img src={insta} alt="uk_logo" class="logo" />
            </div>
            {/* <div>
              <img src={bird} alt="uk_logo" class="logo" />
            </div> */}
            <div>
              <img src={linkdin} alt="uk_logo" class="logo" />
            </div>
          </div>
        </div>

        <div className="f-1_page4">
          <p className="footer_heading"> NATIONAL GAMES 2025 </p>
          <div
            style={{ marginBottom: "10px" }}
            className="fontstyle footer_open_close "
          >
            <span name="ng24" onClick={() => openCloseAccordian("ng24")}>
              {isActiveFooter?.ng24 ? "-" : "+"}
            </span>
          </div>
          {(windowSize?.width > 1100 || isActiveFooter?.ng24) &&
            footer1.map((option, index) => (
              <div key={index} className="footer-fields_page4">
                <a
                  href={option?.path || "/"}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {option.name}
                </a>
              </div>
            ))}
        </div>
        {/* ---------------- */}
        <div className="f-2_page4">
          <p className="footer_heading"> SPORTS </p>
          <div
            // style={{ marginBottom: "10px" }}
            className="fontstyle footer_open_close"
          >
            <span onClick={() => openCloseAccordian("sport")}>
              {isActiveFooter?.sport ? "-" : "+"}
            </span>
          </div>
          {(windowSize?.width > 1100 || isActiveFooter?.sport) &&
            sportsOptions.map((option, index) => (
              <div key={index} className="footer-fields_page4">
                <a
                  href={option?.path || "/"}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  {option.name}
                </a>
              </div>
            ))}
        </div>
        {(windowSize?.width > 1100 || isActiveFooter?.sport) && (
          <>
            <div className="f-3_page4 mt-5">
              {footer3.map((option, index) => (
                <div key={index} className="footer-fields_page4">
                  <a
                    href={option?.path || "/"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {option.name}
                  </a>
                </div>
              ))}
            </div>
            <div className="f-4_page4 mt-5">
              {footer4.map((option, index) => (
                <div key={index} className="footer-fields_page4">
                  <a
                    href={option?.link || "/"}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    {option.name}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {/* ---------------- */}

        <div className="f-5_page4">
          <p className="footer_heading">Contact us </p>
          <div
            style={{ marginBottom: "10px" }}
            className="fontstyle footer_open_close"
          >
            <span onClick={() => openCloseAccordian("ukgoc")}>
              {isActiveFooter?.ukgoc ? "-" : "+"}
            </span>
          </div>
          {(windowSize?.width > 1100 || isActiveFooter?.ukgoc) && (
            <>
              {/* <div className="footer-fields_page4">Director Sports,</div> */}
              <div className="footer-fields_page4">
                National Games Secretariat Maharana Pratap <br />
                Sports College Campus, Thano Road, Raipur <br />
                Dehradun-248008
              </div>
              {/* <div className="footer-fields_page4">
                Maharana Pratap Sports College
                <br /> Campus,Raipur, Dehradun
              </div> */}
              {/* <div className="footer-fields_page4">Pin- 248008</div> */}
              {/* <div className="footer-fields_page4">Ph- 0135-2781414</div> */}
              <div className="footer-fields_page4">
                Email Id : ngsuttarakhand@ gmail.com
              </div>
            </>
          )}

          {/* <div
            style={{ marginTop: "10px", marginBottom: "10px" }}
            className="fontstyle"
          >
            DOWNLOAD APP
          </div> */}
          {/* <div>
            <img src={playstore1} alt="play-store" class="appstore_logo" />
          </div>
          <div>
            <img src={playstore2} alt="apple-store" class="appstore_logo" />
          </div> */}
        </div>
      </div>

      <div
        className={`chatbot-section ${
          isChatbotFixed ? "chatbot-fixed " : "chatbot-above-footer"
        }`}
      >
        <div>
          <img width="35" src={man} alt="" />
          <button className="helpline-text">Helpline</button>
        </div>
        {/* <div>
          <img width="60" src={chatbot} alt="" />
        </div> */}
        <div>
          <Footerbottom />
        </div>
      </div>

      <div className="footer-lower_page4">
        <div className="footer-left">
          Copyright © Uttarakhand National Games organizing committee
        </div>
        <div className="footer-right">
          {/* <div>Visitors : 5553232</div> */}
          <div>Terms and Condition</div>
          <div>PRIVACY Policy</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
