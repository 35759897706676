import React, { useState, useRef, useEffect } from "react";
import NavLogo from "./NavLogo";
import { useHistory } from "react-router-dom";
import burgerMenu from "../../../assets/home2/header/Menu.png";
import search from "../../../assets/home2/header/search.png";
import profile from "../../../assets/home2/header/profile-icon.svg";
import LoginUser from "./LoginBtn/index";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles.css";
import Scores from "components/SubNavbar/Scores";
import Games from "components/SubNavbar/Games";
import Schedule from "components/SubNavbar/Schedule";
import Teams from "components/SubNavbar/Teams";
import MileStones from "components/SubNavbar/Milestones";
import Latest from "components/SubNavbar/Latest";
import Marque from "./Marque/Index";

// images
import uk1 from "../../../assets/home2/Body/uk1.png";
import uk2 from "../../../assets/home2/Body/uk2.png";
import uk3 from "../../../assets/home2/Body/uk3.png";
import uk4 from "../../../assets/home2/Body/uk4.png";
import uk5 from "../../../assets/home2/header/uk1.png";
const Index = () => {
  const history = useHistory();
  const [openNavbar, setOpenNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [width, setWidth] = useState(window.innerWidth < 992);
  const wrapperRef = useRef(null);
  const [activeLink, setActiveLink] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggle, settoggle] = useState(false);
 
  const subMenuList = [
    {
      submenu: "games",
      list: [
        { label: "Records", path: "/games/records" },
        { label: "Reports", path: "/games/reports" },
        { label: "Medal Tally", path: "/" },
        { label: "Visit", path: "/" },
        { label: "National Games", path: "/info/about-national-games" },
        { label: "Mauli (Mascot)", path: "/info/about-mauli" },
        { label: "Visit", path: "/" },
        { label: "Contact UKGOC", path: "/" },
        { label: "Uttarakhand", path: "/" },
        { label: "IOA", path: "/info/about-ioa" },
        { label: "Partners", path: "/" },
        { label: "Logo", path: "/" },
      ],
    },
    {
      submenu: "schedule",
      list: [
        { label: "Overview", path: "/latest/news" },
        { label: "Daily", path: "/latest/photos" },
        { label: "Cluster", path: "/latest/videos" },
      ],
    },
    {
      submenu: "scores",
      list: [
        { label: "All Sport", path: "/" },
        { label: "Olympics Sports", path: "/" },
        { label: "Indigenous Sports", path: "/" },
      
      ],
    },
    {
      submenu: "teams",
      list: [
        { label: "All Teams", path: "/teams" },
        { label: "States", path: "/teams" },
        { label: "Union Territories", path: "/teams" },
        { label: "Sports Boards", path: "/teams" },
       
      ],
    },
    {
      submenu: "News & Gallery",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
        { label: "MILESTONES", path: "/milestone/all" },
      ],
    },
    // {
    //   submenu: "milestones",
    //   list: [
    //     { label: "News", path: "/latest/news" },
    //     { label: "Photos", path: "/latest/photos" },
    //     { label: "Videos", path: "/latest/videos" },
    //   ],
    // },
    // {
    //   submenu: "stats",
    //   list: [
    //     { label: "News", path: "/latest/news" },
    //     { label: "Photos", path: "/latest/photos" },
    //     { label: "Videos", path: "/latest/videos" },
    //   ],
    // },
  ];

  const scrollToTop = () => {
    const element = document.getElementById("home-body");
    if (element) {
      element.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const handleClick = (menu) => {
    if (selectedMenu == menu?.submenu) {
      setIsMenuOpen((b_val) => !b_val);
      return;
    }

    setSelectedMenu(menu?.submenu);
    setIsMenuOpen(true);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  // useEffect(() => {
  //   if (isMobileMenuOpen) {
  //     document.body.style.overflow = "hidden"; // Prevent background scroll
  //   } else {
  //     document.body.style.overflow = "auto"; // Enable background scroll when menu is closed
  //   }

  //   return () => {
  //     document.body.style.overflow = "auto"; // Cleanup on unmount
  //   };
  // }, [isMobileMenuOpen]);
  return (
    <div className="navbar-main">
      <Marque />
      <div className="navbar-fields">
        <div className="left-navbar-items">
          <div className="burger-container">
            <img
              onClick={toggleMobileMenu}
              src={burgerMenu}
              alt="logo"
              className="logo hamburgerMenu"
              style={{
                width: "40px",
                marginBottom: "0px",
              }}
            />
          </div>
          <div className="navLogo">
            <NavLogo
              click={() => {
                scrollToTop();
              }}
            />
          </div>
        </div>
        <div className="nav-items-dropdows">
          {subMenuList.map((menu, index) => (
            <div
              key={index}
              onClick={() => {
                setActiveLink(index);
                handleClick(menu);
                settoggle(!toggle);
              }}
            >
              <h6 className={`${index === activeLink ? "header_active_menu" : null}`}>
                {menu.submenu.toUpperCase()}
                {menu.submenu.toUpperCase() !== "STATS" &&
                  menu.submenu.toUpperCase() !== "SCORES" && (
                    toggle && index === activeLink ? (
                      <i className={`bi bi-chevron-up ${index === activeLink ? "header_active_menu dowen_arrow" : ""}`}></i>
                    ) : (
                      <i className={`bi bi-chevron-down ${index === activeLink ? "header_active_menu" : ""}`}></i>
                    )
                    
                  )}
              </h6>
            </div>
          ))}
        </div>
        <div className="right-navbar-items">
          <div className="menu_search_box">
            <div>
              <i className="bi bi-search"></i>
              <input type="text" placeholder="Search..." />
            </div>
          </div>
          <div className="login-btn-none">
            <LoginUser />
          </div>
        </div>

        <div className="searchBtn">
          <img
            src={profile}
            alt="profile"
            style={{ width: "40px", marginBottom: "0px" }}
          />
        </div>
      </div>

      {/* <div className="logo_wrapper_mobile_container">
        <div className="logo_wrapper_mobile">
          <div>
            <img src={uk3} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div className="mx-2">
            <img src={uk4} alt="logo" className="logo" width="25" height="25" />
          </div>

          <div className="mx-2">
            <img src={uk2} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div className="mx-1">
            <img src={uk5} alt="logo" className="logo" width="25" height="25" />
          </div>
          <div>
            <img src={uk1} alt="logo" className="logo" width="25" height="25" />
          </div>
        </div>
      </div> */}

      {isMenuOpen && !isMobile && (
        <div className="sub-navbar-main">
          {selectedMenu === "scores" && (
            <Scores setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "games" && <Games setIsMenuOpen={setIsMenuOpen} />}
          {selectedMenu === "schedule" && (
            <Schedule setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "teams" && <Teams setIsMenuOpen={setIsMenuOpen} />}
          {selectedMenu === "milestones" && (
            <MileStones setIsMenuOpen={setIsMenuOpen} />
          )}
          {selectedMenu === "News & Gallery" && (
            <Latest setIsMenuOpen={setIsMenuOpen} />
          )}
        </div>
      )}

      {isMobile && isMobileMenuOpen && (
        <>
          <div className="mobile-menu">
            {subMenuList.map((menu, index) => (
              <div
                key={index}
                className={`mobile-menu-item ${
                  isMenuOpen && selectedMenu === menu.submenu ? "open" : ""
                }`}
              >
                <h6
                  className={`${index === activeLink ? "list-item" : "p-3"} `}
                  onClick={() => {
                    setActiveLink(index);
                    handleClick(menu);
                    settoggle(!toggle);
                  }}
                >
                  {menu.submenu.toUpperCase()}
                  {menu.submenu.toUpperCase() !== "STATS" &&
                    menu.submenu.toUpperCase() !== "SCORES" &&
                    (toggle && activeLink === index ? (
                      <i className="bi bi-chevron-up px-2"></i>
                    ) : (
                      <i className="bi bi-chevron-down px-2"></i>
                    ))}
                </h6>
                {isMenuOpen && selectedMenu === menu.submenu && (
                  <ul className="mobile-submenu">
                    {menu.list.map((item, itemIndex) => (
                      <li key={itemIndex} className="mobile-submenu-list-item">
                        <a href={item.path}>{item.label}</a>
                        <i class="bi bi-arrow-right text-white"></i>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>

          {/* mapping */}
          {/* <div className="sub-navbar-main">
            {selectedMenu === "scores" && (
              <Scores setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "games" && (
              <Games setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "schedule" && (
              <Schedule setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "teams" && (
              <Teams setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "milestones" && (
              <MileStones setIsMenuOpen={setIsMenuOpen} />
            )}
            {selectedMenu === "latest" && (
              <Latest setIsMenuOpen={setIsMenuOpen} />
            )}
          </div> */}
        </>
      )}
    </div>
  );
};

export default Index;
